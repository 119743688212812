import React from "react";
import { withToastManager } from "react-toast-notifications";
import VerticalNav from "../../Components/Dashboard/VerticalNav/VerticalNav";
import HorizontalNav from "../../Components/Dashboard/HorizontalNav/HorizontalNav";
import useDashboardLogic from "../../Hooks/useDashboardLogic";
import sapaImg from "../../admin/app-assets/images/dashboard/backup sapaa2.png";
import { BackupGoalsLink } from "../../RouteLinks/RouteLinks";
import { Link } from "react-router-dom";

const SapaChallenge = (props) => {
  const { reload } = props;

  const { state, accountProps, ...otherProps } = useDashboardLogic(reload);

  return (
    <React.Fragment>
      <div
        className="vertical-layout vertical-menu-modern
                 2-columns fixed-navbar  menu-expanded pace-done"
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
      >
        <HorizontalNav userName={accountProps.userName} />
        <VerticalNav userName={accountProps.userName} />

        <div className="container my-4">
          <div className="col-md-6 mx-auto">
            <h1 className="text-dark text-center mb-2">
              The Journey Against Sapa
            </h1>
            <Link to={BackupGoalsLink}>
              <img
                className="my-3"
                src={sapaImg}
                style={{ width: "-webkit-fill-available" }}
                alt=""
              />
            </Link>
            <p>
              It is no longer news that January can be the most difficult month
              financially. Therefore, we are introducing the #SaveAgainstSapa
              challenge.
            </p>
            <br />
            <p>
              Starting from November 1st to December 31st, 2021, we are all
              encouraged to start a Backup Goal on our Backup Cash account with
              the aim of #savingagainstsapa in January.
            </p>
            <br />
            <p>
              With a minimum of Sixty Thousand Naira, you stand the chance to
              win cash prizes and other consolation prizes.
            </p>
            <br />
            <p>How To Participate:</p>
            <br />
            <ol>
              <li>Open a Backup Cash account</li>
              <li>
                Create a backup Goal with a minimum goal amount of N60,000
                between November 1 and December 31, 2021
              </li>
              <li>Take a screenshot of the plan name</li>
              <li>Share this on social media and tag @backupcash</li>
              <li>Stay committed to your backup goal</li>
            </ol>
            <br />
            <a class="link-a bg-secondary mr-3 rounded" href={BackupGoalsLink}>
              Join Challenge
            </a>
            <br />
            <p className="text-center mt-4">Terms and Conditions Apply</p>
            <br />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withToastManager(SapaChallenge);
