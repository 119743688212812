import React, { useEffect, useState } from "react";
import HorizontalNav from "../../Components/Dashboard/HorizontalNav/HorizontalNav";
import VerticalNav from "../../Components/Dashboard/VerticalNav/VerticalNav";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import TableDisplay from "../../Components/Reuseable/TableDisplay";
import { getUserRequest } from "../../redux/auth/action";
import {
  getSnapRequest,
  getHistoryRequest,
  snapSettingsRequest,
} from "../../redux/snap/action";
import { formatNumber } from "../../Helpers/Helper";
import swal from "sweetalert";
import moment from "moment";
import { useHistory, useParams } from "react-router";

const SnapDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataSource, setdataSource] = useState([]);
  const { data, processing } = useSelector((state) => state.snap.all);
  const snapTransfer = useSelector((state) => state.snap.transfer);

  useEffect(() => {
    if (snapTransfer.success) {
      swal("Transfer Successful", "Your Transfer was successful", "success", {
        button: false,
        timer: 2000,
      });
    }
  }, [snapTransfer.success]);
  useEffect(() => {
    dispatch(getSnapRequest());
    dispatch(getUserRequest());
    dispatch(getHistoryRequest());
    dispatch(snapSettingsRequest());
  }, []);

  useEffect(() => {
    if (Array.isArray(data)) {
      let snapHistory = data.filter((dt) => dt.id !== id);
      setdataSource(snapHistory[0].snap_history.reverse());
    }
  }, [data]);

  const columns = [
    {
      title: "date",
      dataIndex: "created_at",
      render: (value, record) => (
        <span className="d-flex flex-column">
          <span style={{ minWidth: "90px" }}>
            {moment(value).format("MMM Do YYYY")}&nbsp;
          </span>
          <small className="text-muted">{moment(value).format("h:mm a")}</small>
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (value, record) => (
        <p style={{ minWidth: "150px" }} className={"text-primary"}>
          {value}
        </p>
      ),
    },
    {
      title: "amount",
      render: (value, record) => (
        <p style={{ minWidth: "150px" }} className={"text-success"}>
          {" "}
          {record.balance != null
            ? `+ ₦ ${formatNumber(parseFloat(record.balance).toFixed(2))}`
            : "N/A"}
        </p>
      ),
    },
  ];
  return (
    <SnapDetails.Wrapper>
      <div
        className="vertical-layout vertical-menu-modern 2-columns fixed-navbar  menu-expanded pace-done"
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
      >
        <HorizontalNav />
        <VerticalNav />

        <TableDisplayHolder>
          <button
            className="btn btn-sm round btn-primary ml-4"
            onClick={() => history.goBack()}
          >
            {"< back"}
          </button>
          <TableDisplay
            header="All Snap Savings"
            columns={columns}
            dataSource={dataSource}
            loading={processing}
            pagination
          />
        </TableDisplayHolder>
      </div>
    </SnapDetails.Wrapper>
  );
};
SnapDetails.Wrapper = styled.div`
  /*
Default style to get basic item to show
margin-left: 17rem;
margin-top: 5rem;
 */
  background-color: #f7fbff;
  height: 100vh;
`;

const TableDisplayHolder = styled.div`
  margin-left: 17rem;
  margin-top: 5rem;
  @media screen and (max-width: 768px) {
    margin-left: 2rem;
  }
`;
export default SnapDetails;
