import Axios from "axios";
import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillPatchExclamationFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { BvnFormLink } from "../../RouteLinks/RouteLinks";
import UpdateBvn from "./UpdateBvn";
import "./style.css";
function VirtualAccount({ showVirtual }) {
  const [updateBvn, setUpdateBvn] = useState(false);
  const [virtualdetails, setVirtualDetails] = useState();
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  async function Callendpoint() {
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}sfsbapi/v1/user/virtual-account`,
      config
    )
      .then((response) => {
        console.log(response.data);
        if (response.status === 201) {
          toast.info("Virtual Account Created");
        } else {
          window.location = "/dashboard/bvn-form";

          alert("created");
        }
        showVirtual(false);
      })
      .catch((error, response) => {
        console.log(error);
      });
  }

  useEffect(() => {
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}sfsbapi/v1/user/virtual-account`,
      config
    )
      .then((response) => {
        console.log(response.data);
        setVirtualDetails(response.data);
      })
      .catch((error, response) => {
        console.log(error);
      });
  }, []);
  console.log(virtualdetails);

  return (
    <div
      // style={{
      //   position: "fixed",
      //   backgroundColor: "rgba(0, 0, 0, 0.578)",
      //   width: "100%",
      //   height: "100%",
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   zIndex: "99999999",
      // }}
      className="cont24"
    >
      {updateBvn ? (
        ""
      ) : (
        <div className="ttop">
          <div className="mainn">
            <h1>Create Virual Account</h1>

            <AiOutlineClose
              size={25}
              onClick={() => {
                showVirtual(false);
              }}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "35px" }}
          >
            <div
              // style={{
              //   padding: "20px",
              //   display: "flex",
              //   flexDirection: "column",
              //   borderRadius: "20px",
              //   gap: "10px",
              // }}
              className="important"
            >
              <span style={{ color: "#52E260" }}>Important:</span>
              <p
                style={{
                  display: "flex",
                  gap: "10px",
                  fontSize: "17px",
                }}
              >
                <BsFillPatchExclamationFill
                  style={{ color: "#52E260" }}
                  size={20}
                />
                Creating this account allows you a third-party to send money to
                your instant save
              </p>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "17px",
                }}
              >
                {" "}
                <BsFillPatchExclamationFill
                  style={{ color: "#52E260" }}
                  size={15}
                />
                Fund your account via external bank account{" "}
              </p>
            </div>
            <h3 style={{ textAlign: "center", fontSize: "20px" }}>
              Do you want to proceed to create a<br /> Virtual Account
            </h3>
            <div
              // style={{
              //   display: "flex",
              //   justifyContent: "space-between",
              //   gap: "20px",
              // }}
              className="Proceed"
            >
              <button
                style={{
                  width: "50%",
                  height: "50px",
                  border: "1px solid gainsboro",
                  borderRadius: "10px",
                  fontSize: "20px",
                }}
                onClick={() => {
                  showVirtual(false);
                }}
              >
                No
              </button>
              <button
                style={{
                  backgroundColor: "#4079FF",
                  width: "50%",
                  height: "50px",
                  borderRadius: "10px",
                  color: "white",
                  fontSize: "20px",
                }}
                // onClick={() => {
                //   setUpdateBvn(true);
                // }}
                onClick={Callendpoint}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* {updateBvn ? (
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            width: "35%",
            height: "45%",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "7rem",
              borderBottom: "1px solid gainsboro",
            }}
          >
            <h1>Update your BVN</h1>

            <AiOutlineClose
              size={25}
              onClick={() => {
                showVirtual(false);
              }}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <label style={{ fontSize: "20px" }}>Input your BVN</label>
            <input type="text" placeholder="BVN" />
          </div>
        </div>
      ) : (
        ""
      )} */}

      {/* {updateBvn ? <UpdateBvn CloseBvn={setUpdateBvn} /> : ""} */}
    </div>
  );
}

export default VirtualAccount;
