import React, { useState, useRef, useEffect } from "react";
// import { Home } from "@/public/Icon";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { BsBank } from "react-icons/bs";
import { RiFileCopyFill } from "react-icons/ri";
import ClipLoader from "react-spinners/ClipLoader";
import BarLoader from "react-spinners/BarLoader";
import { CiCircleQuestion } from "react-icons/ci";
import { TbCircleDotted } from "react-icons/tb";
import { toast } from "react-toastify";

function InstantSaveTransfer() {
  // const [seconds, setSeconds] = useState(10); // 10 minutes in seconds

  const [seconds, setSeconds] = useState(600); // 10 seconds
  const [seconds2, setSeconds2] = useState(1800); // 10 seconds
  const [showAlert, setShowAlert] = useState(false);

  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#2DCA72");
  const [done, setDone] = useState(false);
  const [tranferstate, setTransferState] = useState();

  const [copy, setCopy] = useState();
  const [copy1, setCopy1] = useState();

  const token = JSON.parse(localStorage.getItem("token"));
  const user_id = JSON.parse(localStorage.getItem("transactioniddetails"));
  const transaction_id = localStorage.getItem("transaction_id");

  useEffect(() => {
    if (seconds === 0) {
      toast.error("Server Error");
      // setShowAlert(true);
      // setLoading(false);
      // alert("Status Worked");
      // Display alert after the countdown
      setTimeout(() => {
        window.location = "/dashboard/instant-save";
        // alert("Hi!");
      }, 1000);
    }
  }, [seconds]);

  useEffect(() => {
    let interval = null;

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    let interval = null;

    if (seconds2 > 0) {
      interval = setInterval(() => {
        setSeconds2((seconds) => seconds - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [seconds2]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  // async function VerifyAccount(e) {
  //   e.preventDefault();
  //   setOpen(false);
  //   setLoading(true);
  //   const response = await fetch(
  //     `${process.env.REACT_APP_BASE_URL}sfsbapi/v1/user/virtual-account/verify/transaction`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({ transaction_id }),
  //     }
  //   );
  //   const server = await response.json();
  //   console.log(server);

  //   if (server?.data?.status === "processing") {
  //     setLoading(true);
  //     toast.info("processing");
  //   } else if (server?.data?.status === "success") {
  //     setDone(true);
  //     setTimeout(() => {
  //       window.location = "/dashboard/instant-save";
  //     }, 1000);
  //   }
  //   // if (response.status === 200) {
  //   //   setLoading(false);
  //   // }
  //   // if (server?.error) {
  //   //   toast.error(server?.error);
  //   //   setLoading(false);
  //   // } else {
  //   //   toast.success(server?.message);
  //   //   setLoading(false);
  //   //   // setTimeout(() => {
  //   //   //   window.location = "/dashboard/instant-save";
  //   //   // }, [3000]);
  //   // }
  // }
  async function VerifyAccount(e) {
    e.preventDefault();
    setOpen(false);
    setLoading(true);

    const checkStatus = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}sfsbapi/v1/user/virtual-account/verify/transaction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ transaction_id }),
        }
      );
      const server = await response.json();
      console.log(server);

      if (server?.data?.status === "processing") {
        setLoading(true);
        // toast.info("processing");
        setTimeout(checkStatus, 2000); // Recursive call with a delay of 2 seconds
      } else if (server?.data?.status === "success") {
        setDone(true);
        setTimeout(() => {
          window.location = "/dashboard/instant-save";
        }, 1000);
      } else {
        toast(server?.data?.status);
      }
    };

    await checkStatus(); // Initial call to start the recursive function
  }

  function HandleCopy() {
    navigator.clipboard
      .writeText(user_id?.data?.account_number)
      .then(() => {
        setCopy(true);
        toast.success("Copied");
      })
      .catch((error) => {
        toast.error("Copy failed: " + error);
      });
  }
  function HandleCopy2() {
    navigator.clipboard
      .writeText(user_id?.data?.amount)
      .then(() => {
        setCopy1(true);

        toast.success("Copied");
      })
      .catch((error) => {
        toast.error("Copy failed: " + error);
      });
  }

  return (
    <div>
      {open && (
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            justifyContent: "center",
            // borderTop: "1px solid gray",
            padding: "20px",
          }}
        >
          <p style={{ textAlign: "center" }}>
            Make a bank transfer to the account below to fund your instant save{" "}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              border: "1px solid blue",
              borderStyle: "dashed",
              borderRadius: "10px",
              padding: "10px",
              backgroundColor: "gray",
              fontSize: "15px",
              backgroundColor: "gainsboro",
            }}
          >
            <div>
              <span style={{ fontSize: "15px" }}>Bank Name</span>
              <h3>{user_id?.data?.bank_name}</h3>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                gap: "150px",
                // justifyContent: "space-around",
              }}
            >
              <div>
                <span style={{ fontSize: "15px" }}>Account Number</span>
                <h3>{user_id?.data?.account_number} &nbsp; &nbsp;</h3>
              </div>
              <span
                // onClick={() => {
                //   navigator.clipboard.writeText(user_id?.data?.account_number),
                //     toast.success("copied");
                // }}
                onClick={HandleCopy}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "13px",
                  backgroundColor: `#4079FF`,
                  color: "white",
                  borderRadius: "5px",
                  padding: "3px",
                  cursor: "pointer",
                }}
              >
                <RiFileCopyFill style={{ color: "white" }} />{" "}
                {copy ? "Copied" : "Copy"}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "15px" }}>Account Name</span>
              <h3>SFS Mybackupcash</h3>
            </div>

            <div
              style={{ display: "flex", alignItems: "center", gap: "150px" }}
            >
              <div>
                <span style={{ fontSize: "15px" }}>Amount</span>
                <h3>
                  {user_id?.data?.amount} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </h3>
              </div>
              <span
                // onClick={() => {
                //   navigator.clipboard.writeText(user_id?.data?.amount),
                //     toast.success("copied");
                // }}
                onClick={HandleCopy2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "13px",
                  backgroundColor: "#4079FF",
                  color: "white",
                  borderRadius: "5px",
                  padding: "3px",
                  marginLeft: "-30px",
                  marginBottom: "-10px",
                  cursor: "pointer",
                }}
              >
                <RiFileCopyFill style={{ color: "white" }} />{" "}
                {copy1 ? "Copied" : "Copy"}
              </span>
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            className="btnn"
          >
            <p>Use this account for this transaction only</p>
            <div>
              <BsBank
                size={55}
                style={{
                  backgroundColor: "#169D07",
                  color: "white",
                  padding: "10px",
                  borderRadius: "22px",
                }}
              />
            </div>
            {/* <span>Expire in 30:00</span> */}
            <button
              style={{ width: "100%" }}
              className={"round-lg btn-custom-blue modal-btn"}
              onClick={VerifyAccount}
            >
              I have sent the money
            </button>
            <button
              style={{
                border: "1px solid gray",
                height: "40px",
                width: "100%",
                fontSize: "17px",
                borderRadius: "5px",
              }}
            >
              Please wait for {formatTime(seconds2)}
            </button>
          </div>
        </div>
      )}

      {!open && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "50px",
            textAlign: "center",
            padding: "40px",
            width: "100%",
          }}
        >
          {done ? (
            ""
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "80px",
              }}
            >
              {loading ? (
                <p style={{ fontSize: "25px" }}>
                  We are waiting to confirm your transfer.
                </p>
              ) : (
                ""
              )}

              {loading && (
                // <ClockLoader loading={loading} size={100} color={color} />
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <AiFillCheckCircle size={50} style={{ color: "#2DCA72" }} />
                  <BarLoader loading={loading} size={100} color={color} />
                  <TbCircleDotted size={50} style={{ color: "#2DCA72" }} />
                </div>
              )}
              {loading && (
                <button
                  style={{
                    border: "1px solid gray",
                    height: "40px",
                    width: "100%",
                    fontSize: "17px",
                    borderRadius: "5px",
                  }}
                >
                  Please wait for {formatTime(seconds)}
                </button>
              )}
            </div>
          )}

          {done && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <AiFillCheckCircle size={70} style={{ color: "#2DCA72" }} />
              <h3>Done</h3>
              <p style={{ fontSize: "20px" }}>
                We will process and verify within 24-48 working hours and update
                your active savings
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default InstantSaveTransfer;
