import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  BankCardLink,
  BvnFormLink,
  KycSettingLink,
} from "../../../../RouteLinks/RouteLinks";
import "./stepper.css";
import Axios from "axios";

const Stepper = () => {
  const [user, setUser] = useState();
  const [getcard, setGetCard] = useState();
  const [card, setCard] = useState();
  const [bank, setBank] = useState();

  const userDetails = useSelector((state) => state.auth.data);
  const loading = useSelector((state) => state.auth.processing);
  const card_and_bank = userDetails.authorization?.data;
  const channel = user?.data?.authorization?.data;

  console.log(channel);

  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}sfsbapi/v1/user`, config).then(
      (response) => {
        // console.log(response?.data);
        setUser(response?.data);
      }
    );
    // .catch((err) => {
    //   err?.response?.code ||
    //     (err?.response?.code === 500 && "") ||
    //     toast.error(err?.message);
    //   setLoading(false);
    // });
  }, []);
  console.log(user);

  function getChannelCard() {
    let isgetcard = false;
    channel &&
      channel.map((ch) => {
        if (ch?.channel === "card") {
          isgetcard = true;
        }
      });
    return isgetcard;
  }
  console.log(getChannelCard());

  function getChannelBank() {
    let isgetbank = false;
    channel &&
      channel.map((ch) => {
        if (ch?.channel === "card") {
          isgetbank = true;
        }
      });
    return isgetbank;
  }
  console.log(getChannelBank());

  function getBank() {
    let isBank = false;
    card_and_bank &&
      card_and_bank.map((dt) => {
        if (dt.channel === "bank") {
          isBank = true;
        }
      });
    return isBank;
  }
  // console.log(getBank());

  function getCard() {
    let isgetCard = false;
    card_and_bank &&
      card_and_bank.map((dt) => {
        setGetCard(dt?.channel);
        isgetCard = true;
      });
    return isgetCard;
  }
  getCard();

  return (
    (userDetails.is_bvn_verified === 0 ||
      !getChannelBank() ||
      !getChannelCard()) &&
    !loading && (
      <div class="row">
        <div class="col-md-12">
          <h1 className="text-danger">Please complete your registration</h1>
          <ul class="stepper stepper-horizontal">
            <li
              class={
                userDetails.is_bvn_verified === 1 ? "completed" : "warning"
              }
            >
              <Link to={BvnFormLink} title="Click to add BVN">
                <span
                  class="circle"
                  style={{
                    backgroundColor:
                      user?.data?.is_bvn_verified === 1 ? "green" : "gray",
                  }}
                >
                  1
                </span>
                <span class="label">BVN</span>
              </Link>
            </li>

            <li class={getBank() ? "completed" : "warning"}>
              <Link to={BankCardLink}>
                <span
                  class="circle"
                  title="Click to add bank details"
                  style={{
                    backgroundColor:
                      getChannelBank() === true ? "green" : "gray",
                  }}
                >
                  2
                </span>
                <span class="label">Bank</span>
              </Link>
            </li>

            <li class={getCard() ? "completed" : "warning"}>
              <Link to={BankCardLink} title="Click to add card">
                <span
                  class="circle"
                  style={{
                    backgroundColor:
                      getChannelCard() === true ? "green" : "gray",
                  }}
                >
                  3
                </span>
                <span class="label">Card</span>
              </Link>
            </li>
            <li class="warning">
              <Link to={KycSettingLink} title="Click to add KYC">
                <span class="circle">4</span>
                <span class="label">KYC</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    )
  );
};

export default Stepper;
