import React from "react";
import "./style.css";
const PrivacyPolicy = () => {
  return (
    <>
      <div className="head">
        <h1>DATA PROTECTION & PRIVACY POLICY</h1>
      </div>
      <div className="main">
        <span>
          At <b>BackUpCash,</b> we understand the importance of protecting an
          individual’s right to privacy. In the course of providing a service to
          you as an individual or corporate client, we may obtain personal
          information about you. Obtaining this information is important to our
          ability to deliver the highest level of service to you, but we are
          also committed to safeguarding the privacy of your information at all
          times.This Privacy Policy describes our treatment of your personal
          information on all our platforms and is designed to help you
          understand how we may process any personal information obtained from
          you.
        </span>
        <div className="content">
          <h1>1. Consent</h1>
          <p>
            By providing your personal information to us, you have signified
            your acceptance of our Privacy Policy and agree that we may collect,
            use and disclose your personal information for specified purposes as
            described in this Privacy Policy.
          </p>
        </div>
        <div className="content">
          <h1>2. What Personal Data do we collect</h1>
          <p>
            The kinds of personal information that we collect will vary
            depending on the type of dealings we have with you and how you
            interact with us. Generally, we may process the following
            information: Personal Identification Information – Where you invest
            in a fund or other products of <b>BackUpCash</b>, make payments for
            our products and services, respond to a survey, or fill out a form,
            we will request for your demographic information, email address,
            telephone number, occupation and any other information we may deem
            necessary. Statistical Information – We may collect statistical
            information relating to your use of our website or other online
            services, including your server address, the pages you accessed, and
            documents downloaded, the type of browser you are using, technical
            information about your means of connection to our Site, such as the
            operating system and the internet service providers utilized. We
            will not normally be able to identify you from such statistical
            information. We may also collect personal data in the following
            ways: Information from our social media sites: We may collect
            information through your engagement with us on our social media
            sites (Facebook, Instagram, LinkedIn, Twitter). This includes your
            replies to our posts, your comments, enquiries and support messages.
            However, we will only ask for information required to help us be of
            service to you. Third Parties: We may also receive your information
            from third parties such as financial institutions and service
            providers. Job Application: When you apply for a job with us, we
            will request Personal Data about your education and employment
            history. As part of your application, you will be asked to provide
            your express consent to our use of this information to assess your
            application and any monitoring activities which may be required of
            us under applicable laws as an employer. We may also carry out
            screening checks (reference, background and criminal record checks).
            We may exchange your Personal Data with academic institutions,
            recruiters, health maintenance organisations, law enforcement
            agencies, referees and your previous employers. Without your
            Personal Data, we may not be able to process your application for
            positions with us. We do not collect information of minors.
          </p>
        </div>
        <div className="content">
          <h1>3. Why Do We Collect Your Personal Data</h1>
          <p>
            <b>BackUpCash</b> collects your personal information in order to
            facilitate and manage our relationship with you. Specifically, we
            collect personal data for the following purposes: To process your
            application for investment in our funds or other products and
            establish your investment in our funds or products For Internal
            record keeping To respond to your enquiries To improve our products
            and services To provide you with information concerning products,
            services and opportunities we offer. We will not send unsolicited
            marketing communications to you by SMS or email if you have not
            opted in to receive them. Additionally, you can withdraw your
            consent at any time and free of charge To comply with legal and
            regulatory obligation and protection ourselves and you against fraud
            To assess proposed Data Subjects’ employability and other employee
            benefits-related purposes We do not sell or market your personal
            information or personalised data to third parties.
          </p>
        </div>
        `{" "}
        <div className="content">
          <h1>4. What are our Collection Methods?</h1>
          <p>
            Typically, we receive personal data directly from Data Subjects. We
            may also receive personal data from third parties. The following are
            methods through which we collect personal information: Direct
            collection: Know Your Customer (KYC) forms Forum and feedback forms
            Enquiry forms Digital touch points Electronic means (emails, website
            and App) Employee engagement personal data forms Third party’s data
            collection source: Individuals nominated and authorised by the Data
            Subject to engage us on his/her behalf Clients engaged to conduct
            screening checks on newly employed staff before confirmation of
            appointment. In the case of data obtained from third party source, a
            copy of your consent given to the third party to transfer your data
            to <b>BackUpCash</b> shall suffice for our use and processing.
          </p>
        </div>
        <div className="content">
          <h1>5. How We Use Cookies</h1>
          <p>
            Cookies are small files placed on your device’s browser that enables
            the website to identify your device as you view different pages. We
            use cookies to track browsing history of visitors to improve their
            experience.
          </p>
        </div>
        <div className="content">
          <h1>6. Record Retention Period</h1>
          <p>
            We retain Personal Data for 10 years after your relationship with us
            has ended in order to fulfill the relevant purposes set out in this
            policy and to comply with our legal and regulatory obligations. We
            may retain Personal Data for longer periods if it is in our
            legitimate business interests and required to comply with applicable
            laws. We will continue to use and disclose such Personal Data in
            accordance with this Privacy Policy.
          </p>
        </div>
        <div className="content">
          <h1>7. Share Your Personal Data</h1>
          <p>
            We may share your Personal Data or other information about you with
            others for the following reasons: With other companies that provide
            services to us: We may share Personal Data with third-party service
            providers that perform services and functions at our direction and
            on our behalf. These third-party service providers may, for example,
            provide you with services, verify your identity or provide customer
            support. With other third parties for our business purposes or as
            permitted or required by law: We may share information about you
            with other parties for our business purposes or as permitted or
            required by law, including: if we need to do so to comply with a
            law, legal process or regulations; to investigate violations of or
            enforce a user agreement or other legal terms applicable to any
            service; to companies that we plan to merge with or be acquired by;
            and to support our audit, compliance, and corporate governance
            functions. With your consent: We also will share your Personal Data
            and other information with your consent.
          </p>
        </div>
        <div className="content">
          <h1>8. What Are You Right</h1>
          <p>
            I. Requests to Access, Rectify or Erase 1. Access Request You have
            the right to ask us whether we hold any Personal Data relating to
            you and, if we do, to be provided with a copy of that Personal Data
            in electronic form, unless you want to receive it in another way
            (for example, a paper copy). In addition, you can ask us for
            information on how we use your Personal Data, who we share it with,
            how long we keep it, where it is stored, and other information to
            help you understand how we use it. 2. Rectification Request You have
            the right to ask us to correct your Personal Data if it is
            inaccurate and to have incomplete Personal Data updated without
            undue delay. If we cannot correct the Personal Data, we include a
            note on our files regarding your request to correct your Personal
            Data. 3. Erasure Request You have the right to ask us to erase your
            Personal Data if: Your Personal Data are no longer necessary for the
            purpose(s) they were collected for Your Personal Data have been
            unlawfully processed Your Personal Data must be erased to comply
            with a regulation You withdraw your consent for the processing of
            the Personal Data (and if this is the only basis on which we are
            processing your Personal Data) You object to processing that is
            based on our legitimate interests, provided there are no overriding
            legitimate grounds for continued processing, or You object to
            processing for direct marketing purposes. If we have made the
            Personal Data concerned public, we will also take reasonable steps
            to inform other data controllers processing the data so they can
            seek to erase links to or copies of your Personal Data. We may
            refuse to act on your request to erase your Personal Data if the
            processing of your Personal Data is necessary: To exercise our right
            of freedom of expression and information To comply with the NDPR and
            relevant Nigerian laws For the performance of a task carried out in
            the public interest or to exercise official authority vested in us
            To establish, exercise or defend legal claims. In these cases, we
            can restrict the processing instead of erasing your Personal Data if
            requested to do so by you. II. Requests to Object You have the right
            to object at any time to the processing of your Personal Data if we
            process it based on our legitimate interests. This includes any
            so-called “profiling”. Our privacy notice informs you when we rely
            on legitimate interests to process your Personal Data. In these
            cases, we will stop processing your Personal Data unless we can
            demonstrate compelling legitimate reasons for continuing the
            processing. We may reject your request if the processing of your
            Personal Data is needed to establish, exercise or defend legal
            claims. III. Requests to Restrict You have the right to ask us to
            restrict the processing of your Personal Data if: You contest the
            accuracy of your Personal Data and we are in the process of
            verifying the Personal Data we hold The processing is unlawful and
            you do not want us to erase your Personal Data We no longer need
            your Personal Data for the original purpose(s) of processing, but
            you need them to establish, exercise or defend legal claims and you
            do not want us to delete the Personal Data as a result, or You have
            objected to processing carried out because of our legitimate
            interests while we verify if our legitimate grounds override yours.
            If processing is restricted, we may process your Personal Data
            (except for storage purposes), only: If you have given us your
            consent For establishing, exercising or defending legal claims For
            protecting the rights of another natural or legal person, or For
            reasons of important public interest as defined under the NDPR and
            relevant Nigerian laws Once processing is restricted following your
            request, we will inform you before we lift the restriction. IV.
            Requests for Portability You have the right to ask that we transfer
            any personal information that you have provided to us to another
            third party. Once transferred, the other party will be responsible
            for safeguarding such personal information. Even if you request the
            portability of your Personal Data, you retain your right to also
            request their erasure. V. Requests to Object to Automated Decisions
            Generally, you have the right to object to any decision producing a
            legal effect concerning you or which otherwise significantly affects
            you if this is based solely on the automated processing of your
            Personal Data. This includes automated decisions based on profiling.
            We may refuse your request if the decision in question is: Necessary
            to enter into a contract with you, or for the performance of your
            contract with us, or Permitted by regulations We will only make
            decisions relying solely on automated processing that involve your
            sensitive Personal Data if you have given your explicit consent or
            the processing is necessary for reasons of substantial public
            interest, based on the NDPR and relevant laws.
          </p>
        </div>
        <div className="content">
          <h1>9. How Do We Protect Your Personal Data</h1>
          <p>
            All of your personal information is stored in a secure environment,
            where electronic and procedural safeguards have been put in place to
            mitigate against loss, misuse, damage or unauthorized access. The
            security measures include firewalls, physical access controls to our
            premises, CCTV cameras for public safety and quality control as well
            as information access authorisation controls. While we are dedicated
            to securing our systems and services, you are responsible for
            securing and maintaining the privacy of your password(s) and
            account/profile registration information and verifying that the
            Personal Data we maintain about you is accurate and current. We will
            inform you of any breaches which may affect your Personal Data.
          </p>
        </div>
        <div className="content">
          <h1>10. Remedies for Violation and Time-frame for Remedy</h1>
          <p>
            In the event of violation of this policy, our Data Protection
            Officer shall within 7 days redress the violation. Where the
            violation pertains to the disclosure of your Personal Data without
            your consent, such information shall be retracted immediately, and
            confirmation of the retraction sent to the you within 48 hours of
            the redress.
          </p>
        </div>
        <div className="content">
          <h1>11. Changes to this Privacy Policy</h1>
          <p>
            Our Privacy Policy may change from time to time. We will post any
            privacy policy changes on our website and, if the changes are
            significant, we will provide a more prominent notice (including, for
            certain services, email notification of privacy policy changes).
            Unless otherwise stated, the current version shall supersede and
            replace all previous versions of this Policy. You acknowledge and
            agree that it is Your responsibility to review this Policy
            periodically and become aware of modifications.
          </p>
        </div>
        <div className="content">
          <h1>12. Contact Us</h1>
          <p>
            If you have any general questions or concerns about this Privacy
            Policy or the way in which we handle your Personal Data, kindly
            contact us via the details below: <b>BackUpCash</b> NIGERIA LIMITED
            <br /> 287 Ajose Adeogun Street
            <br /> Victoria Island, Lagos
            <br /> Email: enquiries@sfsnigeria.com
            <br /> Phone Number: 01-280 1400
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;

/* <div className="container">
                <div className="row">
                    <div className="col-12 py-5">
                        <h2 className='mb-3'>Backup Cash 21 Day Savings Challenge - Terms and Conditions</h2>

                        <h5>Qualification Criteria</h5>
                        <p className='mb-5'>
                            Promotion will run for 3 weeks (from the 1st of November,
                            2019 to 21st of November, 2019).<br/>
                            Winners will be announced by the end of campaign<br/>
                            The promotion is open to customers aged 16 and above.
                            Minors must show approval from parents or guardian.<br/>

                            Steady save must be opened to join the challenge<br/>

                            For the duration of the project,
                            customers must save a minimum of N500 every time they save<br/>

                            Customers can win prizes from 4 distinct categories.
                            All winners will be announced by end of campaign<br/>
                        </p>

                        <h5><u>Criteria for Winning</u></h5>

                        <h5>Category 1 – N250,000 grand prize (One winner)</h5>
                        <p className='mb-5'>To qualify for this category, participants must save at least N500
                            for a minimum of 21 cumulative days over
                            the 21-day period. <br/>
                            Participant with the most consistent savings and highest referrals wins
                            If two participants have the same total savings,
                            number of referrals will used to determine the winner
                        </p>

                        <h5>Category 2 – N150,000 prize (One winner)</h5>
                        <p className='mb-5'>To qualify for this category, participants must save
                            at least N500 for a minimum of 18 cumulative days over the 21-day period. <br/>
                            Participant with the most consistent savings and
                            highest referrals wins<br/>
                            If two participants have the same total savings,
                            number of referrals will used to determine the
                            winner
                        </p>


                        <h5>Category 3 – N100,000 prize (One winner)</h5>
                        <p className='mb-5'>To qualify for this category, participants must save at least N500
                            for a minimum of 15 cumulative days over the 21-day period.<br/>
                            Participant with the most consistent savings and highest referrals wins<br/>
                            If two participants have the same total savings,
                            number of referrals will used to determine the winner
                        </p>


                        <h5>Category 4 – Consolation prizes (Six winners)</h5>
                        <p>This will include six (6) winners (4th place to 10th place)</p>
                        <p>To win, participants must save at least N500 for a minimum of 8
                            cumulative days over the 21-day period.</p>
                        <p>Participants with the highest number of referrals,
                            that do not qualify for category 1 – 3 will be treated as category 4 winners</p>
                        <h5><u>Publicity Agreement</u></h5>
                        <p className='mb-5'>
                            We may undertake publicity activities relating to competitions
                            and prize awards. The winner therefore agrees to the use of their name,
                            photograph and disclosure of town or region of residence in any
                            post-prize-winning publicity names, surnames, towns or regions
                            of residence and prize details.
                        </p>
                        <h5>Contact details</h5>
                        <p className='mb-5'>This prize competition is run by Backup Cash, provided by SFS Capital
                            Our registered office is 287 Ajose Adeogun St, Victoria Island, Lagos
                            You can contact us by direct message on our social media channels on
                            Instagram and Twitter - @mybackupcash
                            Facebook - @backupcash
                        </p>

                        <p className='mb-5'>
                            <em>
                                By participating in the 21 Day Savings Challenge,
                                you accept the above terms and conditions.
                            </em>
                        </p>

                        <p className='mb-5'>
                            <em>Backup Cash reserves the right to modify these terms.
                                Any changes to the competition will be notified to
                                entrants as soon as possible by us.
                            </em>
                        </p>


                    </div>
                </div>
            </div> */
