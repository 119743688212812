import React, { useState, useEffect } from "react";
import totalSavingsIcon from "../../../admin/app-assets/images/svg/transparent-total-saving-icon.svg";
import totalBalanceIcon from "../../../admin/app-assets/images/svg/total-balance-icon.svg";
import Axios from "axios";
import { formatNumber } from "../../../Helpers/Helper";
const InstantSaveCard = (props) => {
  const [centralvaultbalance, setCentralVaultBal] = useState();
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}sfsbapi/v1/user`, config).then(
      (response) => {
        console.log(response?.data);
        setCentralVaultBal(response?.data?.data?.accounts?.data?.[0]?.balance);
      }
    );
  }, []);
  console.log(centralvaultbalance);

  const formattedAmount = new Intl.NumberFormat().format(centralvaultbalance);

  return (
    <React.Fragment>
      <div className="card pull-up blue-card saving-card">
        <img className="floated-icon" src={totalSavingsIcon} alt="icon" />
        <div className="card-content">
          <div className="card-body">
            <h4 className="text-white blue-card-heading ">Central Vault</h4>
            <div className="media d-flex pb-2 ">
              <div className="align-self-center">
                <img
                  className="blue-card-icon"
                  alt="icon"
                  src={totalBalanceIcon}
                />
              </div>
              <div className="media-body text-left pt-1 ">
                <h3 className="text-white clearfix">
                  <strong
                    //   className="blue-card-price ml-2 mr-2"
                    className="ml-1"
                  >
                    <strong>₦</strong>&nbsp;
                    {formatNumber(
                      parseFloat(Math.abs(centralvaultbalance)).toFixed(2)
                    )}
                    {/* {formattedAmount} */}
                  </strong>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InstantSaveCard;
