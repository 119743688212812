export const CREATE_SNAP_REQUEST = "CREATE_SNAP_REQUEST";
export const CREATE_SNAP_SUCCESS = "CREATE_SNAP_SUCCESS";
export const CREATE_SNAP_ERROR = "CREATE_SNAP_ERROR";
export const CLEAR_SNAP_STATE = "CLEAR_SNAP_STATE";

export const GET_SNAP_REQUEST = "GET_SNAP_REQUEST";
export const GET_SNAP_SUCCESS = "GET_SNAP_SUCCESS";

export const INIT_SNAP_REQUEST = "INIT_SNAP_REQUEST";
export const INIT_SNAP_SUCCESS = "INIT_SNAP_SUCCESS";
export const INIT_SNAP_ERROR = "INIT_SNAP_ERROR";

export const VERIFY_SNAP_REQUEST = "VERIFY_SNAP_REQUEST";
export const VERIFY_SNAP_SUCCESS = "VERIFY_SNAP_SUCCESS";
export const VERIFY_SNAP_ERROR = "VERIFY_SNAP_ERROR";

export const GET_HISTORY_REQUEST = "GET_HISTORY_REQUEST";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";

export const TRANSFER_INTEREST_REQUEST = "TRANSFER_INTEREST_REQUEST";
export const TRANSFER_INTEREST_SUCCESS = "TRANSFER_INTEREST_SUCCESS";

export const RESET_STATE_ERROR = "RESET_STATE_ERROR";

export const GET_SNAP_SETTINGS_REQUEST = "GET_SNAP_SETTINGS_REQUEST";
export const GET_SNAP_SETTINGS_SUCCESS = "GET_SNAP_SETTINGS_SUCCESS";
export const GET_SNAP_SETTINGS_ERROR = "GET_SNAP_SETTINGS_ERROR";
