import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import SimpleReactValidator from "simple-react-validator";
import ClipLoader from "react-spinners/ClipLoader";
import InstantSavingModal from "../InstantSavingModal";
import { NumericFormat } from "react-number-format";
import { request } from "../../../../ApiUtils/ApiUtils";
import { BsFillExclamationOctagonFill } from "react-icons/bs";
import Axios from "axios";
import {
  bankTransfer,
  instantSaveEndpoint,
} from "../../../../RouteLinks/RouteLinks";
import {
  ADD_BANK,
  MIN_INSTANT_SAVE,
  USERINFO,
} from "../../../Auth/HOC/authcontroller";
import { withToastManager } from "react-toast-notifications";
import ButtonLoader from "../../../Auth/Buttonloader/ButtonLoader";
import {
  formatNumber,
  getCardsFromStorage,
  toastMessage,
} from "../../../../Helpers/Helper";
import {
  getUserCards,
  initTransaction,
  verifyTransaction,
} from "../../../../actions/CardAction";
import { _payWithPaystack } from "../../../../utils";
import { toast } from "react-toastify";
import "./style.css";
class InstantSavingForm extends Component {
  defaultForm = {
    tamount: "",
    amount: "",
    payment_auth: "",
    source: "quick",
  };

  state = {
    form: {
      amount: null,
      payment_auth: null,
      source: "quick",
    },
    loading: false,
    color: "white",
    second: false,
    userCards: [],
    disableButton: false,
    err: null,
    Card: true,
    BankT: false,
    amount: "",
    tamount: "",
    source: "quick",
  };

  showSecond = () => {
    this.state.second = true;
  };

  TransferAmount = (e) => {
    this.setState({ TransferAmount: e.target.value });
  };

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      validators: {
        payment_auth: {
          // name the rule
          message: "Please Select a card",
          required: "Please Select a card",
        },
      },
    });
    console.log(props);

    this.getUserCards = this.getUserCards.bind(this);
  }

  //paystack

  initiatePayStack = () => {
    const { amount } = this.state.form;
    //send api
    if (amount == null || parseFloat(amount) < 500) {
      toastMessage(
        "A minimum of ₦500 is required to add a card",
        "error",
        this
      );
      this.setState({
        loading: false,
        disableButton: false,
      });
    } else {
      initTransaction(
        {
          amount: parseFloat(this.state.form.amount),
          source: "quick",
        },
        (status, payload) => {
          this.setState({ loading: false });
          if (status) {
            _payWithPaystack(
              payload.reference,
              payload.amount,
              this.resolvePaystackResponse
            );
          } else {
            this.props.toastManager.add(payload, {
              appearance: "error",
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
          }

          this.props.onHide();
        }
      );
    }
  };

  resolvePaystackResponse = (response) => {
    this.setState({
      loading: false,
    });
    verifyTransaction(
      {
        ref: response.reference,
        type: "instant",
      },
      (status, payload) => {
        if (status) {
          toastMessage("Card Added Successfully", "success", this);
          this.getUserCards();
          //reload all instant saves
          this.props.updateInstantSave();
          this.props.setupInstantSave();
        } else {
          toastMessage("Unable to add card at this moment", "error", this);
        }
      }
    );
  };

  getUserCards() {
    getUserCards((status, payload) => {
      if (status) {
        this.setState({ cards: payload });
      } else {
        this.props.toastManager.add("Unable to fetch Cards", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    });
  }

  TransferVirtual = async (e) => {
    e.preventDefault();
    // this.setState({
    //   loading: true,
    //   disableButton: true,
    // });

    if (this.state.tamount === "") {
      toast.error("Amount filled is required");
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: true,
      });
    }
    if (this.state.tamount < 500) {
      toast.error("A minimum 0f 500 is required to make a transfer");
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: true,
      });
      const { tamount, source } = this.state;
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/sfsbapi/v1/user/transaction/init`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ amount: tamount, source }),
        }
      );
      const server = await response.json();
      console.log(server);
      this.setState({
        loading: false,
        disableButton: true,
      });
      // alert(server?.data?.id);

      let transaction_id = server?.data?.id;
      localStorage.setItem("transaction_id", transaction_id);
      this.setState({
        loading: true,
        disableButton: true,
      });

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/sfsbapi/v1/user/virtual-account/payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ transaction_id }),
        }
      );
      const end = await res.json();
      // console.log(end);

      this.setState({
        loading: false,
      });
      localStorage.setItem("transactioniddetails", JSON.stringify(end));
      this.props.isClick();
    }
  };

  //submit steady save form
  submitForm = (e) => {
    const { form } = this.state;
    e.preventDefault();
    this.showSecond();
    if (this.validator.allValid()) {
      if (
        Number(form.amount) < MIN_INSTANT_SAVE &&
        parseInt(this.state.form.payment_auth) != ADD_BANK
      ) {
        this.setState({
          loading: false,
          disableButton: false,
        });
        toastMessage(
          "The minimum amount for an instant save is ₦500",
          "error",
          this
        );
      } else {
        this.setState({
          loading: true,
          disableButton: true,
        });

        //if add bank is selected
        if (parseInt(this.state.form.payment_auth) === ADD_BANK) {
          //initiate paystack
          this.initiatePayStack();
        } else if (parseInt(this.state.form.payment_auth) === 101010) {
          request(
            bankTransfer,
            { amount: this.state.form.amount, type: "instant-save" },
            true,
            "POST",
            this.HandleBankTransfer
          );
        } else {
          request(
            instantSaveEndpoint,
            this.state.form,
            true,
            "POST",
            this.HandleInstantSave
          );
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };

  //handle response
  HandleInstantSave = (state, response) => {
    this.setState({
      loading: false,
      disableButton: false,
      form: this.defaultForm,
    });

    if (!state && response) {
      toastMessage(`${JSON.stringify(response.data.message)}`, "error", this);
    }

    if (state && response) {
      toastMessage(
        "You have successfully initiated bank transfer, you record will be updated soon.",
        "success",
        this
      );
      setTimeout(() => {
        this.props.onHide(true);
      }, 2000);
    }
  };
  //handle response
  HandleBankTransfer = (state, response) => {
    this.setState({
      loading: false,
      disableButton: false,
      form: this.defaultForm,
    });

    if (!state && response) {
      toastMessage(`${JSON.stringify(response.data.message)}`, "error", this);
    }

    if (state && response) {
      toastMessage(
        "You have successfully created an Instant Save",
        "success",
        this
      );
      setTimeout(() => {
        this.props.onHide(true);
      }, 2000);
    }
  };

  //Retrieves user inputs
  TransferHandler = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const data = { ...this.state.form };
    data[name] = value;
    console.log(event.target.value);
    let curr;
  };

  changeHandler = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const data = { ...this.state.form };
    data[name] = value;
    console.log(event.target.value);
    // let curr;

    if (name === "payment_auth" && value === 0) {
      // initiate paystack
      this.initiatePayStack();
    }
    // if (event.target.value === 0 && name === "payment_auth") {
    //   this.initiatePayStack();
    // }
    // // if (event.target.value === 1 && name === "payment_auth") {
    // //   this.initiatePayStack();
    // // }

    // if (name === "payment_auth" && value === 0) {
    //   this.initiatePayStack();
    //   // this.props.isClick();
    // }

    this.setState({
      form: data,
    });
  };

  componentDidMount() {
    getCardsFromStorage(USERINFO, this);
  }

  //  TransferVirtual = () =>{
  //     Axios.post(
  //        `${process.env.REACT_APP_BASE_URL}sfsbapi/v1/user/virtual-account/payment`,
  //        config
  //      )
  //        .then((response) => {
  //          console.log(response.data);
  //          if (response.status === 201) {
  //            alert("created");
  //          } else {
  //            window.location = "/dashboard/bvn-form";

  //            alert("created");
  //          }
  //          showVirtual(false);
  //        })
  //        .catch((error, response) => {
  //          console.log(error);
  //        });
  //  }

  render() {
    const { payment_auth, amount } = this.state.form;
    const { userCards, data } = this.state;
    return (
      <React.Fragment>
        <div className="topp">
          <span
            style={{
              color: this.state.Card ? "#4079FF" : "gray",
              borderBottom: this.state.Card ? "3px solid #4079FF" : "",
              width: "10rem",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
            onClick={() => {
              this.setState({
                Card: true,
                BankT: false,
              });
            }}
          >
            Card
          </span>
          <span
            style={{
              color: this.state.BankT ? "#4079FF" : "gray",
              borderBottom: this.state.BankT ? "3px solid #4079FF" : "",
              width: "10rem",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
            onClick={() => {
              this.setState({
                BankT: true,
                Card: false,
              });
            }}
          >
            Bank Transfer
          </span>
        </div>
        {this.state.Card && (
          <Form className={"is-modal-form"} onSubmit={this.submitForm}>
            <Form.Row>
              <Col>
                <Form.Group className={"mt-md-1 mb-md-3"}>
                  <Form.Label className="d-block">
                    Amount{" "}
                    {/* <span className="amount-display round float-right text-white px-1">
                    ₦ {formatNumber(Number(amount).toFixed(2))}
                  </span> */}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="₦ 500"
                    name={"amount"}
                    id={"amount"}
                    value={amount}
                    onChange={this.changeHandler}
                  />
                  {/* <NumericFormat
                  style={{
                    border: "none",
                    borderBottom: "1px solid gray",
                    fontSize: "10px !important",
                    outline: "none",
                    marginTop: "5px",
                  }}
                  className="form-control  mb-1"
                  id={"amount"}
                  value={amount}
                  thousandSeparator=","
                  onChange={this.changeHandler}
                  placeholder="₦ 5000"
                /> */}
                  {this.validator.message("amount", amount, "required|numeric")}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col className={"mt-md-1 mb-md-3"}>
                <Form.Group>
                  <Form.Label>Debit Card</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      this.changeHandler(e);
                      setTimeout(() => {
                        this.submitForm(e);
                        //   10 mins
                      }, 600000);
                    }}
                    value={payment_auth}
                    id={"payment_auth"}
                    name={"payment_auth"}
                  >
                    {/* <option value={-1}>Select Card</option> */}
                    <option value={-1}>Select Payment Method</option>
                    <option value={0}>Add Card</option>
                    {/* <option value={1}>Bank Transfer</option> */}
                    {userCards && userCards.length > 0
                      ? this.state.userCards.map((data, index) => {
                          return (
                            <option value={data.id} key={data.id}>
                              [{data.card_type.toUpperCase()} **** **** ****{" "}
                              {data.last4}] [exp: {data.exp_month}/
                              {data.exp_year}]
                            </option>
                          );
                        })
                      : null}
                    {/* <option value={101010}>Make Transfer</option> */}
                  </Form.Control>
                  {this.validator.message(
                    "payment_auth",
                    payment_auth,
                    "required|numeric"
                  )}
                  {parseInt(this.state.form.payment_auth) === 101010 && (
                    <p>
                      Bank Name: UBA <br />
                      Acct. No.: 2094397587 <br />
                      Acc. Name: Joshua Olabiran
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>

            <Form.Row
              className={
                "d-flex justify-content-center justify-content-md-end mt-2"
              }
            >
              <button
                className={"round btn-custom-blue modal-btn"}
                disabled={this.state.loading}
                type="submit"
                onClick={this.submitForm}
                // onClick={() => {
                //   this.props.isClick();
                // }}
              >
                {this.state.loading ? (
                  <ButtonLoader />
                ) : (
                  <span>
                    {parseInt(this.state.form.payment_auth) === 101010
                      ? "Confirm Transfer"
                      : "Proceed"}
                  </span>
                )}
              </button>
            </Form.Row>
          </Form>
        )}

        {this.state.BankT && (
          <Form className={"is-modal-form"} onSubmit={this.submitForm}>
            <Form.Row>
              <Col>
                <Form.Group className={"mt-md-1 mb-md-3"}>
                  <Form.Label className="d-block">
                    Amount{" "}
                    {/* <span className="amount-display round float-right text-white px-1">
                    ₦ {formatNumber(Number(amount).toFixed(2))}
                  </span> */}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="₦ 500"
                    name={"amount"}
                    id={"amount"}
                    value={this.state.tamount}
                    onChange={(event) =>
                      this.setState({ tamount: event.target.value })
                    }
                  />
                  {/* <NumericFormat
                    style={{
                      border: "none",
                      borderBottom: "1px solid gray",
                      fontSize: "10px !important",
                      outline: "none",
                      marginTop: "5px",
                    }}
                    // type="number"
                    className="form-control  mb-1"
                    id={"amount"}
                    value={this.state.tamount}
                    thousandSeparator=","
                    onChange={(event) =>
                      this.setState({ tamount: event.target.value })
                    }
                    placeholder="₦ 5000"
                  /> */}
                  {/* {this.validator.message("amount", amount, "required|numeric")} */}
                </Form.Group>
              </Col>
            </Form.Row>
            {/* <Form.Row>
              <Col className={"mt-md-1 mb-md-3"}>
                <Form.Group>
                  <Form.Label>Debit Card</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      this.changeHandler(e);
                      setTimeout(() => {
                        this.submitForm(e);
                        //   10 mins
                      }, 600000);
                    }}
                    value={payment_auth}
                    id={"payment_auth"}
                    name={"payment_auth"}
                  >
                    <option value={-1}>Select Card</option>
                    <option value="first">Select Card</option>
                    <option value={0}>Add Card</option>
                    <option value={1}>Bank Transfer</option>
                    <option value={1}>Bank Transfer</option>
                    {userCards && userCards.length > 0
                      ? this.state.userCards.map((data, index) => {
                          return (
                            <option value={data.id} key={data.id}>
                              [{data.card_type.toUpperCase()} **** **** ****{" "}
                              {data.last4}] [exp: {data.exp_month}/
                              {data.exp_year}]
                            </option>
                          );
                        })
                      : null}
                    <option value={101010}>Make Transfer</option>
                  </Form.Control>
                  {this.validator.message(
                    "payment_auth",
                    payment_auth,
                    "required|numeric"
                  )}
                  {parseInt(this.state.form.payment_auth) === 101010 && (
                    <p>
                      Bank Name: UBA <br />
                      Acct. No.: 2094397587 <br />
                      Acc. Name: Joshua Olabiran
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Form.Row> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                border: "1px solid blue",
                borderStyle: "dashed",
                borderRadius: "10px",
                padding: "10px",
                fontSize: "15px",
              }}
            >
              <span>Note:</span>
              <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {" "}
                <BsFillExclamationOctagonFill
                  size={15}
                  style={{ color: "#4079FF" }}
                />
                Minimum value for saving is 500 Naira.
              </p>
              <p style={{ display: "flex", gap: "10px" }}>
                <BsFillExclamationOctagonFill
                  size={22}
                  style={{ color: "#4079FF" }}
                />
                Kindly make payment to the account details displayed after
                clicking the button below.
              </p>
              <p style={{ display: "flex", gap: "10px" }}>
                <BsFillExclamationOctagonFill
                  size={20}
                  style={{ color: "#4079FF" }}
                />
                For Bank Transfer you are required to transfer the exact amount
                of money.
              </p>
            </div>
            <Form.Row
              className={
                "d-flex justify-content-center justify-content-md-end mt-2"
              }
            >
              <button
                className={"round btn-custom-blue modal-btn"}
                disabled={this.state.loading}
                type="submit"
                onClick={this.TransferVirtual}
                // onClick={() => {
                //   this.props.isClick();
                // }}
              >
                {this.state.loading ? (
                  <ClipLoader
                    loading={this.state.loading}
                    size={20}
                    color={this.state.color}
                  />
                ) : (
                  <span>
                    {/* {parseInt(this.state.form.payment_auth) === 101010
                      ? "Confirm Transfer"
                      : "Proceed"} */}
                    proceed
                  </span>
                )}
              </button>
            </Form.Row>
          </Form>
        )}
      </React.Fragment>
    );
  }
}

export default withToastManager(InstantSavingForm);
