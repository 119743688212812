import React, {Component} from 'react';

class ButtonLoader extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="loadingSpinner">&nbsp;</div>
            </React.Fragment>
        );
    }
}

export default ButtonLoader;