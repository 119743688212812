import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import Axios from "axios";
import { AiOutlineFileExcel } from "react-icons/ai";
import nodata from "./nodata.png";

function TableCard() {
  const [depositTable, setDepositTable] = useState();
  const [withdrawalTable, setWithdrawalsTable] = useState();
  const [newname, setNewname] = useState("Instant Save");
  const token = JSON.parse(localStorage.getItem("token"));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}sfsbapi/v1/user/transactions/processing`,
      config
    ).then((response) => {
      setDepositTable(response?.data?.data);
    });
  }, []);
  //   console.log(depositTable);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}sfsbapi/v1/user/withdrawals/processing`,
      config
    ).then((response) => {
      setWithdrawalsTable(response?.data?.data);
    });
  }, []);
  console.log(withdrawalTable);

  const [deposits, setDeposit] = useState(false);
  const [withdrawals, setWithdrawals] = useState(true);

  return (
    <div className="col-12 col-lg-6 mb-2">
      <div className="flexx">
        <h2 className="pending">Pending Transactions</h2>

        <div className="type">
          {/* <p
            onClick={() => {
              setDeposit(true);
              setWithdrawals(false);
            }}
            style={{
              backgroundColor: deposits ? "#1C0734" : "",
              color: deposits ? "white" : "",
            }}
          >
            Deposit
          </p> */}
          <p
            onClick={() => {
              setWithdrawals(true);
              setDeposit(false);
            }}
            style={{
              backgroundColor: withdrawals ? "#1C0734" : "",
              color: withdrawals ? "white" : "",
            }}
          >
            Withdrawal
          </p>
        </div>
      </div>
      <div className="Rash">
        {withdrawals && (
          <table className="table">
            {withdrawalTable?.length === 0 ? (
              <div className="Error">
                {/* <AiOutlineFileExcel size={60} dddd/> */}
                <img src={nodata} alt="" />
              </div>
            ) : (
              <>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Account Types</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {withdrawalTable?.map((wt, index) => {
                    const rownumber = index + 1;

                    return (
                      <tr>
                        <td>{rownumber}</td>
                        <td>{wt?.account?.accounttypes?.name}</td>
                        <td>{wt?.amount}</td>
                        <td>{wt?.status}</td>
                        <td>{wt?.created_at.toString().slice(0, 10)}</td>

                        <Link to="/dashboard/instant-save">
                          <td>View</td>
                        </Link>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
          </table>
        )}

        {/* {withdrawals ? (
          ""
        ) : (
          <table className="table">
            {depositTable?.length === 0 ? (
              <div className="Error">
                <img src={nodata} alt="" />
              </div>
            ) : (
              <>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Account Type</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {depositTable?.map((dt, index) => {
                    const rownumber = index + 1;
                    return (
                      <tr>
                        <td>{rownumber}</td>
                        <td>
                          {dt?.account?.accounttypes?.name ===
                          "Standard Savings"
                            ? newname
                            : ""}
                        </td>
                        <td>{dt?.amount}</td>
                        <td>{dt?.status}</td>
                        <td>{dt?.created_at.toString().slice(0, 10)}</td>

                        <Link to="/dashboard/instant-save">
                          <td>View</td>
                        </Link>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
          </table>
        )} */}
      </div>
    </div>
  );
}

export default TableCard;
