import React, { Component } from "react";
import HorizontalNav from "../../Components/Dashboard/HorizontalNav/HorizontalNav";
import VerticalNav from "../../Components/Dashboard/VerticalNav/VerticalNav";
import Form from "react-bootstrap/Form";
import ButtonLoader from "../../Components/Auth/Buttonloader/ButtonLoader";
import SimpleReactValidator from "simple-react-validator";
// import { _handleFormChange } from "../../utils/index";
import { request } from "../../ApiUtils/ApiUtils";
import {
  ChangeName,
  GetUserBVN,
  // GetUserKYC,
  GetUserOTP,
} from "../../RouteLinks/RouteLinks";
import { ToastProvider, withToastManager } from "react-toast-notifications";
import DashboardLoader from "../../Components/Dashboard/DashboardLoader/DashboardLoader";
import { getUserData } from "../../actions/UserAction";
import swal from "sweetalert";
import Footer from "../../Components/Dashboard/Footer/Footer";
import "./bvn.css";
import { toast } from "react-toastify";

class BvnForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      bvn: {
        bvn: "",
      },
      otp: {
        otp: "",
      },
      // bvn: "",
      stepper: 1,
      errCount: 3,
      is_bvn_verified: "",
      showLoader: false,
      userName: null,
    };
    this.validator1 = new SimpleReactValidator();
    this.validator2 = new SimpleReactValidator();

    this.validateBvn = this.validateBvn.bind(this);
    this.validateOtp = this.validateOtp.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleBvn = this.handleBvn.bind(this);
    this.handleOtp = this.handleOtp.bind(this);
  }

  validateBvn(e) {
    e.preventDefault();
    if (!this.validator1.allValid()) {
      this.validator1.showMessages();
      this.forceUpdate();
    } else if (this.state.bvn.bvn.toString().length < 11) {
      toast.success("BVN must have 11 digits ", "error");
    } else {
      this.setState({ loading: true });
      this.getUserBvn();
    }
  }

  validateOtp(e) {
    e.preventDefault();
    if (!this.validator2.allValid()) {
      this.validator2.showMessages();

      this.forceUpdate();
    } else {
      this.setState({ loading: true });
      this.verifyUserOtp();
    }
  }

  changeHandler = (level) => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (!level) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    } else {
      this.setState({
        ...this.state,
        [level]: {
          ...this.state[level],
          [name]: value,
        },
      });
    }
  };

  componentWillMount() {
    this.setState({
      showLoader: true,
    });

    getUserData(this.handleUserInfo);
  }

  handleUserInfo = (status, res) => {
    this.setState({
      showLoader: false,
    });
    if (status) {
      this.setState({
        userName: res.name,
        is_bvn_verified: res.is_bvn_verified,
      });
    }
  };

  toastMessage(message, status) {
    const { toastManager } = this.props;
    toastManager.add(message, {
      appearance: status,
      autoDismiss: false,
      autoDismissTimeout: 8000,
      pauseOnHover: false,
    });
  }

  getUserBvn() {
    request(GetUserBVN, this.state.bvn, true, "POST", this.handleBvn);
  }

  handleBvn(status, result) {
    this.setState({ loading: false });

    if (result.data.status === "success") {
      // this.toastMessage(result.data.data, "success");
      toast.success("Your Bvn has been verified", "success");
      this.setState({ stepper: 2 });
      // window.location = "/dashboard";
    } else {
      this.setState({ errCount: this.state.errCount - 1 });
      // this.toastMessage(result.data.error ?? "Incorrect BVN", "error");
      if (this.state.errCount === 0) {
        this.toastMessage("Your Account has been Blocked", "error");
        setTimeout(() => {
          window.location.pathname = "/";
        }, 3000);
      }
      this.toastMessage(
        `${result.data.message} & you have ${this.state.errCount} trials left`,
        "error"
      );
    }
  }

  verifyChangeOfName() {
    request(
      ChangeName,
      {
        confirm: true,
      },
      true,
      "POST",
      this.handleChangeOfName
    );
  }

  handleChangeOfName(status, result) {
    if (result.data.status === "success") {
      this.toastMessage(result.data.data ?? result.data.message, "success");
      setTimeout(() => {
        window.location.pathname = "/dashbaord";
      }, 2000);
    }
  }

  verifyUserOtp() {
    request(GetUserOTP, this.state.otp, true, "POST", this.handleOtp);
  }

  handleOtp(status, result) {
    this.setState({ loading: false });
    if (result.data.status === "success") {
      toast.success("OTP has been verified", "success");
      // this.setState({ stepper: 2 });
      // window.location = "/dashbaord";
    } else {
      result.data.message &&
        swal({
          title: "Confirm Change of Name",
          text: result.data.message,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.verifyChangeOfName();
            swal("Your name has been updated successfully", {
              icon: "success",
            });
            toast.success("OTP has been verified", "success");

            setTimeout(() => {
              window.location = "/dashboard";
            }, 1500);
          } else {
            swal("Your old name is still intact");
          }
        });
      result.data.data && this.toastMessage(result.data.data, "error");
    }
  }

  render() {
    return (
      <React.Fragment>
        <ToastProvider>
          <div
            className="vertical-layout vertical-menu-modern 2-columns fixed-navbar  menu-expanded pace-done"
            data-open="click"
            data-menu="vertical-menu-modern"
            data-col="2-columns"
          >
            <HorizontalNav userName={this.state.userName} />
            <VerticalNav userName={this.state.userName} />

            <div className="app-content content">
              <div className="content-wrapper">
                {this.state.showLoader ? <DashboardLoader /> : null}
                <div className="row mb-4 d-none">
                  <div className="col-12">
                    {/* TODO Add Message box */}
                    {/*<MessageBox/>*/}
                  </div>
                </div>
                <div className="content-header row">
                  <p className="mb-2">&nbsp;</p>
                </div>
                <div className="content-body">
                  {/* <div className="row"> */}
                  {this.state.is_bvn_verified === 1 ? (
                    <h2>Your BVN has been verified </h2>
                  ) : (
                    <div className="d-flex flex-row justify-content-between">
                      <div className="col-lg-12 col-12">
                        <h3 className="gray-header-text mb-2 ">
                          Update Bank Verification Details
                        </h3>
                        {/* <div className="match-height"> */}
                        <div className="row">
                          {this.state.stepper === 1 && (
                            <div className="col-md-5">
                              <Form
                                className="form lock-form"
                                onSubmit={this.validateBvn}
                                encType="multipart/form-data"
                              >
                                <div id="basic-form-layouts">
                                  <div className="card curved-radius">
                                    <div className="card-header">
                                      <h4
                                        className="card-title"
                                        id="basic-layout-colored-form-control"
                                      >
                                        BVN updates
                                      </h4>
                                      <a className="heading-elements-toggle">
                                        <i className="la la-ellipsis-v font-medium-3"></i>
                                      </a>
                                      <div className="heading-elements">
                                        <ul className="list-inline mb-0">
                                          <li>
                                            <a data-action="collapse">
                                              <i className="ft-plus"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="card-content ">
                                      <div className="card-body px-md-5">
                                        <div className="form lock-form">
                                          <div className="form-body">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div className="form-group">
                                                  <Form.Group controlId="bvnForm.password">
                                                    <Form.Label>
                                                      Bank Verification Number
                                                    </Form.Label>
                                                    <Form.Control
                                                      name="bvn"
                                                      type="number"
                                                      onChange={this.changeHandler(
                                                        "bvn"
                                                      )}
                                                      value={this.state.bvn.bvn}
                                                    />
                                                  </Form.Group>
                                                  {this.validator1.message(
                                                    "bvn",
                                                    this.state.bvn.bvn,
                                                    "required"
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <Form.Row
                                            className={
                                              "d-flex justify-content-end"
                                            }
                                          >
                                            <button
                                              className={
                                                "btn btn-custom-blue round "
                                              }
                                              type="submit"
                                            >
                                              {this.state.loading ? (
                                                <ButtonLoader />
                                              ) : (
                                                "Verify"
                                              )}
                                            </button>
                                          </Form.Row>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          )}

                          {/* <div className="match-height"> */}
                          {this.state.stepper === 2 && (
                            <div className="col-md-5">
                              <Form
                                className="form lock-form"
                                onSubmit={this.validateOtp}
                                encType="multipart/form-data"
                              >
                                <div id="basic-form-layouts">
                                  <div className="card curved-radius">
                                    <div className="card-header">
                                      <h4
                                        className="card-title"
                                        id="basic-layout-colored-form-control"
                                      >
                                        OTP
                                      </h4>
                                      <a className="heading-elements-toggle">
                                        <i className="la la-ellipsis-v font-medium-3"></i>
                                      </a>
                                      <div className="heading-elements">
                                        <ul className="list-inline mb-0">
                                          <li>
                                            <a data-action="collapse">
                                              <i className="ft-plus"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="card-content ">
                                      <div className="card-body px-md-5">
                                        <div className="form lock-form">
                                          <div className="form-body">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div className="form-group">
                                                  <Form.Group controlId="otpForm.password">
                                                    <Form.Label>
                                                      Input OTP
                                                    </Form.Label>
                                                    <Form.Control
                                                      name="otp"
                                                      type="password"
                                                      onChange={this.changeHandler(
                                                        "otp"
                                                      )}
                                                      value={this.state.otp.otp}
                                                      placeholder="****"
                                                    />
                                                  </Form.Group>
                                                  {this.validator2.message(
                                                    "otp",
                                                    this.state.otp.otp,
                                                    "required"
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <Form.Row
                                            className={
                                              "d-flex justify-content-end"
                                            }
                                          >
                                            <button
                                              className={
                                                "btn btn-custom-blue round "
                                              }
                                              type="submit"
                                            >
                                              {this.state.loading ? (
                                                <ButtonLoader />
                                              ) : (
                                                "Submit"
                                              )}
                                            </button>
                                          </Form.Row>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </ToastProvider>
      </React.Fragment>
    );
  }
}

export default withToastManager(BvnForm);
