import React, { useState } from "react";
import ButtonLoader from "../../Components/Auth/Buttonloader/ButtonLoader";
import { ToastProvider, withToastManager } from "react-toast-notifications";
import "./review.css";
import { useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { postUserReview } from "../../actions/UserAction";
import { toast } from "react-toastify";

const ReviewForm = (props) => {
  const validator = new SimpleReactValidator();
  const userRate = useSelector((state) => state.auth.data.userRate);
  const [loading, setLoading] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  // send a rating value from the star here, then pass it to the api
  const [hover, setHover] = useState(0);
  // this receives a value on hover and styles it.
  const [comment, setComment] = useState("");

  // const handleReview = (e) => {
  //   const { name, value } = e.target;

  //   setReview({
  //     ...review,
  //     [name]: value,
  //   });
  // };

  // const [review, setReview] = useState({
  //   rating: 0,
  //   hoverValue: 0,
  //   comment: "",
  //   index: 0,
  // });

  const handleChange = (e) => {
    const { value } = e.target;

    setComment(value);
  };
  const toastMessage = (message, status) => {
    const { toastManager } = props;
    toastManager.add(message, {
      appearance: status,
      autoDismiss: true,
      autoDismissTimeout: 4000,
      pauseOnHover: false,
    });
  };
  const submit = (e) => {
    e.preventDefault();
    if (comment.textValue === "" && ratingValue === 0) {
      toastMessage("Invalid Inputs", "error");
    } else {
      setLoading(true);
      postUserReview({ rate: ratingValue, comment }, (status, payload) => {
        if (status) {
          setComment("");
          setRatingValue(0);
          toastMessage("Review Submitted.", "success");
          setTimeout(() => props.onClose(), 2000);
        } else {
          toastMessage("An Error Occurred", "error");
        }
        setLoading(false);
      });
    }
  };

  return (
    userRate === false && (
      <React.Fragment>
        <ToastProvider>
          <form className="review-form" onSubmit={(e) => submit(e)}>
            <div className="px-2">
              <div className="card curved-radius">
                <div className="card-header">
                  <div className="d-flex flex-row ">
                    <h4 className="review-header1">Leave a review</h4>
                    <i className="fas fa-times times" onClick={props.onClose} />
                  </div>
                  <div className="review-message mb-1 p-1">
                    {/* <i className="fas fa-check-circle check" /> */}
                    <p>
                      Thank you for submitting a review, your comments and
                      feedback will help others in their saving journey.
                    </p>
                  </div>
                  <div className=" rating">
                    <p className="review-label">Please rate us: </p>
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <button
                          type="button"
                          key={index}
                          className={
                            index <= (hover || ratingValue) ? "on" : "off"
                          }
                          onClick={() => setRatingValue(index)}
                          onMouseEnter={() => setHover(index)}
                          onMouseLeave={() => setHover(ratingValue)}
                        >
                          <i className={`fas fa-star`} />
                        </button>
                      );
                    })}
                  </div>

                  <div className="form-group">
                    <label className="review-label" htmlFor="name">
                      Care to share more about it?
                    </label>
                    <textarea
                      id="comment"
                      type="text"
                      name="comment"
                      cols="30"
                      rows="4"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <button
                      type={"submit"}
                      className="btn btn-custom-blue round"
                    >
                      {loading ? <ButtonLoader /> : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ToastProvider>
      </React.Fragment>
    )
  );
};

export default withToastManager(ReviewForm);
