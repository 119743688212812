import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import InstantSavingForm from "./InstantSavingForm/InstantSavingForm";
import { ToastProvider } from "react-toast-notifications";
import InstantSaveTransfer from "./InstantSavingForm/InstantSaveTransfer";
import backup from "./back.png";
import "./style.css";
class InstantSavingModal extends React.Component {
  state = {
    second: true,
    isClick: false,
    step: 0,
  };

  // setSecond = () => {
  //   this.state.second = false;
  // };

  constructor(props) {
    super(props);
    console.log(this.props);
  }

  render() {
    // const [show, setShow] = useState(true);
    console.log(this.props);
    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // className={"instant-save-modal steady-save-modal"}
        className=""
      >
        <Modal.Header
          style={{ padding: "20px" }}
          // className={" px-md-3 py-md-3"}
          closeButton={this.props.onHide}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {/* <h4>New Instant Save Plans</h4> */}
            <img src={backup} alt="" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"pb-md-4 px-md-3"}>
          {/* form */}
          <ToastProvider>
            {/* <InstantSavingForm
              onHide={this.props.onHide}
              updateInstantSave={this.props.updateInstantSave}
              setupInstantSave={this.props.setupInstantSave}
              second={this.state.second}
            /> */}
            {this.state.isClick ? (
              <InstantSaveTransfer />
            ) : (
              <>
                {this.state.second && (
                  <InstantSavingForm
                    onHide={this.props.onHide}
                    updateInstantSave={this.props.updateInstantSave}
                    setupInstantSave={this.props.setupInstantSave}
                    second={this.state.second}
                    isClick={() => {
                      this.setState({
                        isClick: !this.state.isClick,
                      });
                    }}
                  />
                )}
              </>
            )}
          </ToastProvider>
        </Modal.Body>
      </Modal>
    );
  }
}

export default InstantSavingModal;
